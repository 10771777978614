<template>
  <main class="main">
    <section class="services-area p-80">
      <section class="services">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <section class="services-title">
                <h2>{{$t('services.title')}}</h2>
              </section>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
              <section class="services-img">
                <i class="fas fa-object-group"></i>  
                <h4>{{$t('services.design')}}</h4> 
              </section>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
              <section class="services-img" target="_blank" rel="noopener noreferrer">
                <i class="fas fa-globe"></i> 
                <h4>{{$t('services.web')}}</h4>
              </section>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
              <section class="services-img" target="_blank" rel="noopener noreferrer">
                <i class="fas fa-mail-bulk"></i>
                <h4>{{$t('services.marketing')}}</h4>  
              </section>
            </div>
            <div class="col-lg-12">
                <div class="contact">
                    <p>{{$t('services.info')}}</p>
                    <router-link class="no-margin-left" :to="{name: 'About'}">{{$t('services.call')}}</router-link>
                    <router-link :to="{name: 'Contact'}">{{$t('services.write')}}</router-link>
                </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import setDocumentTitle from '../util'
export default {
  name: 'Portfolio',
  mounted(){
      this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentTitle(this.$t("navigation.services"))
      },
      { immediate: true }
    )
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.services-area{
  @include views-general;
  .services{
    @include views;
    .services-title{
      @include section-title;
    }
    .services-img{
        text-align: center;
        font-size: 50px;
        
    }
    .contact{
      text-align: center;
      margin-top: 68px;
      p{
          margin-bottom: 24px !important;
      }
      a{
        border: 1px solid $white;
        color: $primary;
        padding: 16px 24px;
        border-radius: 5px;
        margin-left: 16px;
        text-transform: uppercase;
        &:hover{
          color: $secondary;
          transition: all ease 0.35s;
        }
      }
      .no-margin-left{
        margin-left: 0px;
      }
    }
  }
}

</style>